import React, { useEffect, useState } from 'react';
import RequestFormLayout from 'components/layout/RequestFormLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { loadVisitRequestForm, updateVisitRequestForm } from 'core/visitRequestForm/visitRequestFormApi';
import SiteSelection from 'components/Form/SiteSelection';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';

const SiteSelectionForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { stepData, visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { site } = visitRequest;
    const defaultSiteId = site?.id;
    const navigation = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [selectedSite, setSelectedSite] = useState<string>(defaultSiteId ? defaultSiteId.toString() : '');

    const handleNextClick = (navigationState: Record<string, unknown>) => {
        let error = { message: '', description: '' };
        if (selectedSite === null) {
            error = {
                message: 'Site Not Selected',
                description: 'Please select a site to proceed',
            };
        }

        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return;
        }

        if (selectedSite !== null && id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 1,
                data: { siteId: selectedSite, forcedStep: 1 },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation(`/visitor-request/${id}/step/2`, { state: navigationState });
                }
            });
        }
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 1,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit requst to proceed',
            });
        }
    };

    const handleSelect = (siteId: string) => {
        setSelectedSite(siteId);
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    return (
        <RequestFormLayout
            currentStep={1}
            isNextEnabled
            onNextClick={handleNextClick}>
            <SiteSelection
                options={stepData.sites}
                onSelect={handleSelect}
                selectedSiteId={selectedSite} />
        </RequestFormLayout>
    );
};

export default SiteSelectionForm;
