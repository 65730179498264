import React, { useEffect, useState } from 'react';
import RequestFormLayout from 'components/layout/RequestFormLayout';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import FormDetails from 'components/Form/FormDetails';
import { Button, Modal, notification, Spin } from 'antd';
import './ReviewForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { updateVisitRequestForm, amendActionRequest, cancelAmendmentRequest, loadVisitRequestForm } from 'core/visitRequestForm/visitRequestFormApi';
import Checkbox from 'components/Elements/Checkbox';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import TermsAndConditions from '../components/TermsAndConditionsModal';

const ReviewForm = () => {
    const navigation = useNavigate();
    const { state: navState } = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: string }>();
    const { visitRequest, isUpdating, isAmending } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { isFetching } = useSelector((state: CombinedStates) => state.visitRequestReview);
    const [termModalOpen, setTermModalOpen] = useState(false);
    const [isTermAccepted, setIsTermAccepted] = useState(false);
    const [amendModalOpen, setAmendModalOpen] = useState(false);
    const [signedBy, setSignedBy] = useState('');

    const [amendedSections, setAmendedSections] = useState<string[]>([]);
    const [additionalDetails, setAdditionalDetails] = useState<string>('');
    const amending = navState?.amending;

    const handleSubmit = () => {
        if (!amending) {
            dispatch(updateVisitRequestForm({
                id: String(id),
                step: 6,
                data: {
                    declaration: {
                        agreed_to_terms: isTermAccepted,
                        signature: signedBy,
                    },
                    amending,
                    forcedStep: 6,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation('/dashboard', { state: null });
                }
            });
        } else {
            let error = { message: '', description: '' };
            if (amendedSections.length === 0) {
                error = {
                    message: 'No Amendments Made',
                    description: 'Please select at least one section to amend',
                };
            }

            if (error.message !== '' && error.description !== '') {
                notification.error(error);
                return;
            }

            const sections = amendedSections.map((section) => section);

            dispatch(updateVisitRequestForm({
                id: String(id),
                step: 6,
                data: {
                    declaration: {
                        agreed_to_terms: isTermAccepted,
                        signature: signedBy,
                    },
                    amending,
                    forcedStep: 6,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    dispatch(amendActionRequest({
                        id: String(id),
                        sections,
                        details: additionalDetails,
                    })).then(unwrapResult).then((amendPayload: { message: string, success: boolean }) => {
                        if (amendPayload.success) {
                            navigation(`/visitor-view-request/${id}`, { state: { fromForm: true } });
                        }
                    });
                }
            });
        }
    };

    const handleNextClick = () => {
        let error = { message: '', description: '' };
        if (!isTermAccepted) {
            error = {
                message: 'Terms & Conditions not accepted',
                description: 'Please accept the terms & conditions to proceed.',
            };
        }

        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return;
        }

        if (amending) {
            setAmendModalOpen(true);
        } else {
            handleSubmit();
        }
    };

    const handleAbortChanges = () => {
        if (id !== undefined) {
            dispatch(cancelAmendmentRequest(id)).then(() => {
                navigation(`/visitor-view-request/${id}`, { state: { fromForm: true } });
            });
        }
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 6,
                amending,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit requst to proceed',
            });
        }
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    useEffect(() => {
        if (visitRequest.requester?.name) {
            setSignedBy(visitRequest.requester.name);
        }
    }, [visitRequest]);

    const handleBackClick = (navigationState: Record<string, unknown>) => {
        navigation(`/visitor-request/${id}/step/5`, { state: navigationState });
    };

    const handleRemoveDate = (dateId: number) => {
        if (id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 2,
                data: {
                    selectedDates: visitRequest.dates?.filter((date) => date.id !== dateId),
                    amending,
                    forcedStep: 2,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    handleInitialLoad();
                }
            });
        }
    };

    const handleCheckboxChange = (section: string) => {
        if (amendedSections.includes(section)) {
            setAmendedSections((prev) => prev.filter((s) => s !== section));
        } else {
            setAmendedSections((prev) => [...prev, section]);
        }
    };

    return (
        <div>
            <RequestFormLayout
                currentStep={6}
                isNextEnabled={isTermAccepted}
                isBackEnabled
                onBackClick={handleBackClick}
                nextText="Submit"
                onNextClick={handleNextClick}>
                <Spin spinning={isUpdating || isFetching}>
                    <FormDetails
                        onPressTerm={() => setTermModalOpen(true)}
                        details={visitRequest}
                        id={id}
                        signedBy={signedBy}
                        onAcceptTerm={(accept) => setIsTermAccepted(accept)}
                        onRemoveDate={(dateId) => handleRemoveDate(dateId)}
                        amending={amending} />
                </Spin>
            </RequestFormLayout>
            <Modal title="Terms & Conditions For Access"
                wrapClassName="modal-container"
                onCancel={() => setTermModalOpen(false)}
                footer={null}
                styles={{ body: { maxHeight: '50vh', overflow: 'auto' } }}
                style={{ left: -200 }}
                open={termModalOpen}>
                <TermsAndConditions />
            </Modal>
            <Modal title="Amendments to Request"
                wrapClassName="amend-modal-container"
                onCancel={() => setAmendModalOpen(false)}
                footer={null}
                styles={{ body: { overflow: 'auto' } }}
                open={amendModalOpen}>
                <div>
                    <p>Please select all the sections of your request that you made amendments too. The request will return to the pending approval status until an approver reviews your amendments.</p>
                    <div className="amend-checkbox-wrapper">
                        <div className="checkbox-wrapper">
                            <Checkbox checked={amendedSections.includes('Date or Shift')} onClick={() => handleCheckboxChange('Date or Shift')} />
                            <span className="checkbox-label">Date or Shift</span>
                        </div>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={amendedSections.includes('Visitors')} onClick={() => handleCheckboxChange('Visitors')} />
                            <span className="checkbox-label">Visitors</span>
                        </div>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={amendedSections.includes('Site Access')} onClick={() => handleCheckboxChange('Site Access')} />
                            <span className="checkbox-label">Site Access</span>
                        </div>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={amendedSections.includes('Work Type')} onClick={() => handleCheckboxChange('Work Type')} />
                            <span className="checkbox-label">Work Type</span>
                        </div>
                    </div>
                    <div className="additional-details">
                        <h5 className="heading margin-5">Additional Details (Optional)</h5>
                        <textarea
                            className="details-textarea"
                            value={additionalDetails}
                            placeholder=""
                            onChange={(e) => setAdditionalDetails(e.target.value)} />
                    </div>
                    <div className="button-wrapper">
                        <Button type="primary" className="form-button" onClick={handleSubmit} loading={isAmending || isUpdating}>
                            Save Amendments
                        </Button>
                        <Button type="primary" ghost className="form-button cancel-button" onClick={handleAbortChanges}>
                            Cancel Amendments
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>

    );
};

export default ReviewForm;
